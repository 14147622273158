import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import VideoBanner from "../components/VideoBanner"
import SeeMore from "../components/SeeMore"
import CardPage from "../components/Cards/CardPage"
import CardInsegna from "../components/Cards/CardInsegna"
import CardNews from "../components/Cards/CardNews"
import StoryBanner from "../components/StoryBanner"
import NumbersSection from "../components/NumbersSection"
import Title from "../components/Title"

import sectionMarchi from "../images/card-section-1.jpg"
import sectionImpegnoSociale from "../images/card-section-2.jpg"

import insegnePamPanoramaLogo from "../static/logos/logo-pam-panorama-2.png"
import insegnePamLocalLogo from "../static/logos/logo-local.png"
import insegneDiscountLogo from "../static/logos/logo-ins.png"
import insegneFranchisingLogo from "../static/logos/logo-franchising.png"
import insegneCityLogo from "../static/logos/logo-city.png"
import insegnePamACasaLogo from "../static/logos/logo-pam-a-casa.png"

import insegneFranchisingBg from "../images/insegne/pam-franchising.jpg"
import insegnePamPanoramaBg from "../images/insegne/pam.jpg"
import insegneDiscountBg from "../images/insegne/ins.jpg"
import insegnePamLocalBg from "../images/insegne/pam-local.jpg"
import insegnePamCitylBg from "../images/insegne/pam-city.jpg"
import insegnePamACasaBg from "../images/insegne/pam-a-casa.jpg"
import BannerImgDesktop from "../images/home-slider/banner-gruppo-pam.jpg"
import BannerDesktop from "../images/home-slider/banner-gruppo-pam.webp"
import BannerMobile from "../images/home-slider/banner-mobile-gruppo-pam.webp"
import BadgeImgMobile from "../images/home-slider/banner-mobile-gruppo-pam.jpg"

import "../utils/general.scss"

const IndexPage = ({ pageContext }) => {
    const data = useStaticQuery(graphql`
        {
            allNews(
                limit: 4
                sort: { fields: publishedAt___date, order: DESC }
                filter: { metadatas: { type: { eq: "news" } } }
            ) {
                edges {
                    node {
                        id
                        name
                        image
                        banner
                        publishedAt {
                            date(formatString: "DD/MM/YYYY")
                        }
                        metadatas {
                            name_eng
                        }
                    }
                }
            }
        }
    `)

    const news = data.allNews.edges.map((item, index) => {
        item = item.node
        return (
            <CardNews
                key={index}
                to={`news/${item.id}`}
                image={item.image ? item.image : item.banner}
                date={item.publishedAt.date}
                title={
                    typeof window !== "undefined" &&
                    window.localStorage.language === "en" &&
                    item.metadatas &&
                    item.metadatas.name_eng
                        ? item.metadatas.name_eng
                        : item.name
                }
            />
        )
    })

    return (
        <Layout pageContext={pageContext}>
            <SEO title="Home">
                {typeof window !== "undefined" && (
                    <link href={window.location} rel="canonical" />
                )}
            </SEO>

            <div className="container">
                <VideoBanner
                    videoSrc="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/post/gruppo-pam/gruppo-pam-60-anni.mp4"
                    text={pageContext.localeResources["home.slide1"]}
                />
                {/* 
                <a href="https://65anni.pampanorama.it/la-storia.html">
                    <picture>
                        <source
                            media="(min-width:767px)"
                            srcSet={BannerDesktop}
                            className="rounded"
                        />
                        <img src={BannerMobile} alt="" className="rounded" />
                    </picture>
                </a>
                */}

                {/* Le insegne */}
                <section className="section section-insegne">
                    <Title
                        title={
                            pageContext.localeResources[
                                "home.insegneSection.title"
                            ]
                        }
                    />

                    <CardInsegna
                        to="pam-panorama"
                        bg={insegnePamPanoramaBg}
                        logo={insegnePamPanoramaLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamPanorama"]}`}
                        animDir="right"
                    />
                    <CardInsegna
                        to="pam-local"
                        bg={insegnePamLocalBg}
                        logo={insegnePamLocalLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamLocal"]}`}
                        animDir="left"
                    />
                    <CardInsegna
                        to="pam-city"
                        bg={insegnePamCitylBg}
                        logo={insegneCityLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamCity"]}`}
                        animDir="right"
                    />
                    <CardInsegna
                        to="pam-ins"
                        bg={insegneDiscountBg}
                        logo={insegneDiscountLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamDiscount"]}`}
                        animDir="left"
                    />
                    <CardInsegna
                        to="pam-franchising"
                        bg={insegneFranchisingBg}
                        logo={insegneFranchisingLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamFranchising"]}`}
                        animDir="right"
                    />
                    <CardInsegna
                        to="pam-a-casa"
                        bg={insegnePamACasaBg}
                        logo={insegnePamACasaLogo}
                        title={`${pageContext.localeResources["home.insegneSection.pamACasa"]}`}
                        animDir="left"
                    />
                </section>
            </div>

            <section className="section-fornitori">
                <div className="container section">
                    <h5 className="subtitle green ie">
                        {pageContext.localeResources["home.portal.title"]}
                    </h5>
                    <Title
                        title={pageContext.localeResources["home.portal.text"]}
                    />
                    <a
                        className="btn ie"
                        href="https://www.pampanorama.it/portale-fornitori"
                    >
                        {pageContext.localeResources["home.portal.cta"]}
                    </a>
                </div>
            </section>

            <div className="container">
                {/* <VideoBanner videoSrc="https://it-pampanorama.s3.eu-west-3.amazonaws.com/app_assets/PAM_Dipendenti.mp4" /> */}
                <VideoBanner videoSrc="https://it-pampanorama.s3.eu-west-3.amazonaws.com/app_assets/Pam_anniversario_V8.mp4" />
            </div>

            <NumbersSection pageContext={pageContext} />

            {/* News */}
            <section className="section-news">
                <div className="container section">
                    <Title title="News"></Title>

                    {news}
                    <SeeMore
                        text={pageContext.localeResources["cta.seeAllNews"]}
                        to="news"
                        colorful={true}
                    />
                </div>
            </section>

            {/* Marchi + impegno sociale */}
            <div className="container">
                <section className="section section-news">
                    <CardPage
                        to="marchi"
                        image={sectionMarchi}
                        title={
                            pageContext.localeResources[
                                "home.pages.marchi.title"
                            ]
                        }
                        subtitle={
                            pageContext.localeResources[
                                "home.pages.marchi.subTitle"
                            ]
                        }
                        animDir="right"
                        animOffset="200"
                    />
                    <CardPage
                        href="https://www.pampanorama.it/sostenibilita-ambientale"
                        image={sectionImpegnoSociale}
                        title={
                            pageContext.localeResources[
                                "home.pages.impegnoSociale.title"
                            ]
                        }
                        subtitle={
                            pageContext.localeResources[
                                "home.pages.impegnoSociale.subTitle"
                            ]
                        }
                        animDir="left"
                        animOffset="200"
                    />
                </section>
            </div>

            <StoryBanner
                size="big"
                showButton={true}
                pageContext={pageContext}
            />
        </Layout>
    )
}

export default IndexPage
