import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import language_config from "../../../../language-config";

import "../style.scss";
import "./style.scss";

const CardPage = ({to, href, image, title, subtitle, animDir, animOffset, onClick}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);

    const Wrapper = ({ children }) => {
        if (href) {
            return (<a className="card card-page rounded ie" data-aos={`fade-${animDir}`} data-aos-offset={animOffset} onClick={onClick} href={href}>{children}</a>)
        } else {
            return (<Link className="card card-page rounded ie" to={`${path}${to}`} data-aos={`fade-${animDir}`} data-aos-offset={animOffset}>{children}</Link>)
        }
    }
    return (
        <Wrapper>
            <div className="cardImage rounded" style={{backgroundImage: `url(${image})`}}></div>
            <span className="subtitle gray">{title}</span>
            <h5>{subtitle}</h5>
        </Wrapper>
    )
}

export default CardPage
