import React from "react";
import Title from "../Title";

import numbers1 from "../../images/numbers/numbers-1.png";
import numbers22 from "../../images/numbers/numbers-2-2.png";
import numbers23 from "../../images/numbers/numbers-2-3.png";

import numbers31 from "../../images/numbers/numbers-3-1.png";
import numbers32 from "../../images/numbers/numbers-3-2.png";
import numbers34 from "../../images/numbers/numbers-3-4.png";
import numbers33 from "../../images/numbers/numbers-3-3.png";

import numbers43 from "../../images/numbers/numbers-4-3.png";
import numbers42 from "../../images/numbers/numbers-4-2.png";
import numbers41 from "../../images/numbers/numbers-4-1.png";

import "./style.scss";

const NumbersSection = ({pageContext}) => (
    <section className="section section-numbers colorful-bg">
        <div className="container">
            <Title title={pageContext.localeResources['home.numbersSection.title']} />
            <div className="section-numbers-single first">
                <span>
                    <div className="number" data-aos="fade-right">1040</div>
                    <img alt="" src={numbers1} data-aos="fade-right" data-aos-offset="300" />
                </span>
                <span data-aos="fade-left" data-aos-offset="300">
                    <h3 dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.stores.title']}}></h3>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.stores.text']}}></p>
                </span>
            </div>
            <div className="section-numbers-single second" data-aos="fade-right">
                <span className="ie" data-aos="fade-right" data-aos-offset="300">
                    <h3 dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.mq.title']}}></h3>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.mq.text']}}></p>
                </span>
                <span className="ie">
                    <div className="number" data-aos="fade-left">715 k</div>
                    <img alt="" src={numbers1} data-aos="fade-right" data-aos-offset="0" />
                    <img alt="" src={numbers22} data-aos="fade-left" data-aos-offset="150" />
                    <img alt="" src={numbers23} data-aos="fade-left" data-aos-offset="200" />
                </span>
            </div>
            <div className="section-numbers-single third" data-aos="fade-right">
                <span className="ie">
                    <div className="number" data-aos="fade-right">3</div>
                    <img alt="" src={numbers31} data-aos="fade-right" data-aos-offset="300" />
                    <img alt="" src={numbers32} data-aos="fade-right" data-aos-offset="300" />
                    <img alt="" src={numbers34} data-aos="fade-left" data-aos-offset="150" />
                    <img alt="" src={numbers33} data-aos="fade-down" data-aos-offset="300" />
                </span>
                <span className="ie" data-aos="fade-left" data-aos-offset="300">
                    <h3 dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.fatturato.title']}}></h3>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.fatturato.text']}}></p>
                </span>
            </div>
            <div className="section-numbers-single forth" data-aos="fade-right">
                <span data-aos="fade-right" data-aos-offset="300">
                    <h3 dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.collab.title']}}></h3>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['home.numbersSection.collab.text']}}></p>
                </span>
                <span>
                    <div className="number" data-aos="fade-left">9,5 k</div>
                    <img alt="" src={numbers43} data-aos="fade-left" data-aos-offset="200" />
                    <img alt="" src={numbers42} data-aos="fade-up" data-aos-offset="200" />
                    <img alt="" src={numbers41} data-aos="fade-right" data-aos-offset="200" />
                </span>
            </div>
        </div>    
    </section>  
)

export default NumbersSection
