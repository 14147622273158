import React, { useState, useEffect, useRef } from "react"

import soundOn from "../../images/sound-on.png"
import soundOff from "../../images/sound-off.png"

import "./style.scss"

const VideoBanner = ({ videoSrc, text }) => {
    let [isSoundOn, setSoundOn] = useState(false)
    let [showCit, setShowCit] = useState(false)
    let video = useRef()

    useEffect(() => {
        video.current.muted = !isSoundOn
    }, [isSoundOn])

    useEffect(() => {
        video.current.addEventListener("timeupdate", event => {
            setShowCit(
                video.current &&
                    video.current.currentTime &&
                    video.current.currentTime >= 40
                    ? true
                    : false
            )
        })

        return () => {
            video.current.removeEventListener("timeupdate", () => {})
        }
    }, [])

    return (
        <div className="videoBanner rounded" data-text={text}>
            <button onClick={() => setSoundOn(!isSoundOn)}>
                {isSoundOn ? (
                    <>
                        Disattiva l'audio
                        <img src={soundOn} />
                    </>
                ) : (
                    <>
                        Attiva l'audio
                        <img src={soundOff} />
                    </>
                )}
            </button>

            {text && (
                <h1
                    className={showCit ? "show" : ""}
                    dangerouslySetInnerHTML={{ __html: text }}
                ></h1>
            )}
            <video
                src={videoSrc}
                muted
                autoPlay
                loop
                playsInline
                ref={video}
            ></video>
        </div>
    )
}

export default VideoBanner
