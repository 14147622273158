import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import language_config from "../../../../language-config";

import "./style.scss";

const CardInsegna = ({to, bg, logo, title, animDir, animOffset}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);

    return (
        <Link className="card card-insegna rounded ie" to={`${path}${to}`} data-aos={`fade-${animDir}`}>
            <img alt="" src={bg} className="bg-insegna rounded" />
            <span className="logo-insegna">
                <img alt="" src={logo} />
                <h4>{title}</h4>
            </span>
        </Link>
    )
}

export default CardInsegna
